<template>
  <div class="cgu-page">
    <div class="body pb-40">
    <Politique/>
    </div>
    <div class="footer">
      <the-footer/>
    </div>
  </div>
</template>

<script>
import TheFooter from '../../components/the-footer'
import Politique from '../../components/helper/politique-confidentialité/politique-confidentialité-page.vue'
export default {
  name: 'index',
  components: { Politique, TheFooter },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  @media only screen and (max-width: 600px) {
    .debt {
      width: 100%;
    }
  }

</style>
