<template>
  <div class="page">
    <div class="first">
      <div class="bloc">
        <div class="columns">
          <div class="column is-1">
            <p class="arrow">
                <svg-icon
                  name="ic_arrow"
                  original
                  class="w-8 ml-48 pt-20 cursor-pointer"
                  @click="goToIndex"
                />
            </p>
          </div>
          <div class="column intro">
            <h1 class="title ">{{$t('texte218')}}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bloc1 is-relative">
      <div class="second">
        <div class="columns">
          <div class="column">
<p class="objet_dev break-words text-black text-2xl"><em v-html="$t('texte219')"></em></p><br>
            <h1 v-html="$t('texte220')" class="objet break-words text-black text-4xl font-bold"></h1>
            <div class="-space-y-2">
            <p v-html="$t('texte221')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>
            <div class="flex space-x-3 ml-5">
             <p class="text-5xl -mt-5">.</p>
           <p class="objet_dev break-words  text-xl objet_dev text-black objet_dev" v-html="$t('texte222')"></p>
            </div>
            <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="objet_dev break-words  text-xl objet_dev text-black objet_dev">{{$t('texte223')}}</h1>
            </div>
              <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="objet_dev break-words  text-xl objet_dev text-black objet_dev">{{$t('texte224')}}</h1>
            </div>
              <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="objet_dev break-words  text-xl objet_dev text-black objet_dev">{{$t('texte225')}}</h1>
            </div>
            <p v-html="$t('texte226')" class="objet_dev break-words  text-xl objet_dev text-black objet_dev"></p><br>

           </div>
             <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte227')}}</h1>
            </div>
              <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte228')}}</h1>
            </div>
              <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte229')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte230')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte231')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte232')}}</h1>
            </div>
           <h1 v-html="$t('texte233')" class="objet break-words text-black text-4xl font-bold"></h1>
             <p v-html="$t('texte234')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>
             <p v-html="$t('texte235')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>
              <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte236')}}</h1>
            </div>
              <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte237')}}</h1>
            </div>
            <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte238')}}</h1>
            </div>
              <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte239')}}</h1>
            </div>
              <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte240')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte241')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte242')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte243')}}</h1>
            </div>

           <h1 v-html="$t('texte244')" class="objet break-words text-black text-4xl font-bold"></h1>
           <p v-html="$t('texte245')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

           <h1 v-html="$t('texte246')" class="objet break-words text-black text-4xl font-bold"></h1>
           <p v-html="$t('texte247')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

             <h1 v-html="$t('texte248')" class="objet break-words text-black text-4xl font-bold"></h1>
           <p v-html="$t('texte249')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

              <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte250')}}</h1>
            </div>
             <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte251')}}</h1>
            </div>
            <div class="flex space-x-3 ml-5">
             <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte252')}}</h1>
            </div>
            <div class="flex space-x-3 ml-5">
              <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte253')}}</h1>
            </div>
             <div class="flex space-x-3 ml-5">
              <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte254')}}</h1>
            </div>
            <div class="flex space-x-3 ml-5">
              <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte255')}}</h1>
            </div>
                 <div class="flex space-x-3 ml-5">
                 <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte256')}}</h1>
            </div>
                  <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte257')}}</h1>
            </div>
              <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte258')}}</h1>
            </div>

            <h1 v-html="$t('texte259')" class="objet break-words text-black text-4xl font-bold"></h1>
           <p v-html="$t('texte260')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

                         <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte261')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte262')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte263')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte263')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte264')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte265')}}</h1>
            </div>
                          <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte266')}}</h1>
            </div>
                                      <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte267')}}</h1>
            </div>
                  <p v-html="$t('texte268')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

                <h1 v-html="$t('texte269')" class="objet break-words text-black text-4xl font-bold"></h1>
                  <p v-html="$t('texte270')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

                  <h1 v-html="$t('texte271')" class="objet break-words text-black text-4xl font-bold"></h1>
                  <p v-html="$t('texte272')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

                   <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte273')}}</h1>
            </div>
             <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte274')}}</h1>
            </div>
             <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte275')}}</h1>
            </div>
             <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte276')}}</h1>
            </div>
             <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte278')}}</h1>
            </div>
             <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte279')}}</h1>
            </div>
                         <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte280')}}</h1>
            </div>
                         <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte281')}}</h1>
            </div>
            <p v-html="$t('texte282')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

           <h1 v-html="$t('texte283')" class="objet break-words text-black text-4xl font-bold"></h1>
           <p v-html="$t('texte284')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

           <h1 v-html="$t('texte285')" class="objet break-words text-black text-4xl font-bold"></h1>
           <p v-html="$t('texte286')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>
                         <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte287')}}</h1>
            </div>
                                     <div class="flex space-x-3 ml-5">
               <h1 class="text-5xl -mt-5">.</h1>
              <h1 class="text-xl objet_dev text-black objet_dev">{{$t('texte288')}}</h1>
            </div>

              <h1 v-html="$t('texte289')" class="objet break-words text-black text-4xl font-bold"></h1>
           <p v-html="$t('texte290')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

            <h1 v-html="$t('texte291')" class="objet break-words text-black text-4xl font-bold"></h1>
           <p v-html="$t('texte292')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

            <h1 v-html="$t('texte293')" class="objet break-words text-black text-4xl font-bold"></h1>
           <p v-html="$t('texte294')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev"></p><br>

           <p v-html="$t('texte295')" class="objet_dev break-words_dev  text-xl objet_dev text-black objet_dev mt-3"></p><br>

            <!-- <p v-html="$t('texte215')" class="objet_dev break-words_dev text-black text-xl objet_dev text-black objet_dev"></p><br>
            <h1 v-html="$t('texte216')" class="objet_dev break-words text-black text-4xl font-bold"></h1>
            <p v-html="$t('texte217')" class="objet break-words_dev text-black text-xl objet_dev text-black objet_dev"></p><br> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../icons/ic_arrow'
export default {
  name: 'index.vue',
  data () {
    return {
      icons: { arrow }
    }
  },
  methods: {
    goToIndex () {
      this.$router.push({ path: '/' })
    }
    // goToLink (page) {
    //   this.$router.push({ path: '/' + page })
    // },
    // goToContacts () {
    //   window.open('https://www.atlantiqueassurances.bj/nous-contacter/', '_blank')
    // },
    // goToWebSite () {
    //   window.open('https://www.atlantiqueassurances.bj/', '_blank')
    // },
    // openUrl () {
    //   window.open('https://app.aab.bj/')
    // }
  }
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800,900&display=swap');

  .page {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
  }
  .first {
    background-color: #006FB1;
    text-align: center;
    color: white;
    height: 30em;
    margin-top: -10px;
    padding-left: 0%;
  }

  p.arrow {
    width: 1.5em;
  }
  .title {
    color: white;
    font-weight: 400;
    font-size: 2em;
    margin-top: 10;
    padding-top: 1em;
  }
  p.rema_title {
    font-size: 1em;
  }
  .bloc1 {
    margin-left: 16em;
    margin-right: 16em;
    background-color: white;
    z-index: 99;
    margin-top: -10em;
    box-shadow: -2px 5px 20px 0px rgba(185, 185, 185, 0.25), 0 10px 5px 0 rgba(0, 0, 0, 0);
  }
  .second {
    padding: 2em 1em;
  }
  .objet {
    font-size: 1.5em;
    padding-bottom: 1em;
  }
  .objet_dev {
    font-weight: 300;
  }
  h2 {
    margin-top: -1em;
    padding-bottom: 1em;
  }
  .bloc6 {
    background-color: #006FB1;
    padding: 5em 8em;
    color: white;
  }
  .footer_title {
    font-weight: 600;
    font-size: 1em;
  }
  .infos {
    font-size: 0.9em;
    font-weight: 300;
  }
  .infos a {
    color: white;
  }
  @media only screen and (max-width: 600px) {
    .page {
      width: 100%;
      overflow: hidden;
    padding-left: 0%;
    padding-right: 0%;    }
    .first {
      background-color: #006FB1;
      text-align: center;
      color: white;
      height: 30em;
      margin-top: -10px;
    }

    p.arrow {
      width: 1.5em;
    }
    .title {
      color: white;
      font-weight: 400;
      font-size: 2em;
      margin-top: 10;
      padding-top: 0;
    }
    p.rema_title {
      font-size: 1em;
    }
    .bloc1 {
      margin-left: 1em;
      margin-right: 1em;
      background-color: white;
      z-index: 99;
      top: -6em;
      box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
    }
    .second {
      padding: 2em 1em;
    }
    .objet {
      font-size: 1.5em;
      padding-bottom: 1em;
    }
    .objet_dev {
      font-weight: 300;
    }
    h2 {
      margin-top: -1em;
      padding-bottom: 1em;
    }
    .bloc6 {
      background-color: #16B853;
      padding: 5em 1em;
      color: white;
    }
    .footer_title {
      font-weight: 600;
      font-size: 1em;
    }
    .infos {
      font-size: 0.9em;
      font-weight: 300;
    }
    .infos a {
      color: white;
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 768px) {
    .page {
      width: 100%;
      overflow: hidden;
          padding-left: 0%;
    padding-right: 0%;
    }
    .first {
      background-color: #006FB1;
      text-align: center;
      color: white;
      height: 30em;
      margin-top: -10px;
    }

    p.arrow {
      width: 1.5em;
    }
    .title {
      color: white;
      font-weight: 400;
      font-size: 2em;
      margin-top: 10;
      padding-top: 0;
    }
    p.rema_title {
      font-size: 1em;
    }
    .bloc1 {
      margin-left: 1em;
      margin-right: 1em;
      background-color: white;
      z-index: 99;
      top: -12em;
      box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
    }
    .second {
      padding: 2em 1em;
    }
    .objet {
      font-size: 1.5em;
      padding-bottom: 1em;
    }
    .objet_dev {
      font-weight: 300;
    }
    h2 {
      margin-top: -1em;
      padding-bottom: 1em;
    }
    .bloc6 {
      background-color: #16B853;
      padding: 5em 1em;
      color: white;
    }
    .footer_title {
      font-weight: 600;
      font-size: 1em;
    }
    .infos {
      font-size: 0.9em;
      font-weight: 300;
    }
    .infos a {
      color: white;
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1215px) {
    .page {
      width: 100%;
      overflow: hidden;
          padding-left: 0%;
    padding-right: 0%;
    }
    .first {
      background-color: #006FB1;
      text-align: center;
      color: white;
      height: 30em;
      margin-top: -10px;
    }

    p.arrow {
      width: 1.5em;
    }
    .title {
      color: white;
      font-weight: 400;
      font-size: 2em;
      margin-top: 10;
      padding-top: 1em;
    }
    p.rema_title {
      font-size: 1em;
    }
    .bloc1 {
      margin-left: 8em;
      margin-right: 8em;
      background-color: white;
      z-index: 99;
      top: -7em;
      box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
    }
    .second {
      padding: 2em 1em;
    }
    .objet {
      font-size: 1.5em;
      padding-bottom: 1em;
    }
    .objet_dev {
      font-weight: 300;
    }
    h2 {
      margin-top: -1em;
      padding-bottom: 1em;
    }
    .bloc6 {
      background-color: #16B853;
      padding: 5em 4em;
      color: white;
    }
    .footer_title {
      font-weight: 600;
      font-size: 1em;
    }
    .infos {
      font-size: 0.9em;
      font-weight: 300;
    }
    .infos a {
      color: white;
    }
  }
  @media only screen and (min-width: 1216px) and (max-width: 1407px) {
    .page {
      font-family: 'Roboto', sans-serif;
      width: 100%;
          padding-left: 0%;
    padding-right: 0%;
    }
    .first {
      background-color: #006FB1;
      text-align: center;
      color: white;
      height: 30em;
      margin-top: -10px;
    }

    p.arrow {
      width: 1.5em;
    }
    .title {
      color: white;
      font-weight: 400;
      font-size: 2em;
      margin-top: 10;
      padding-top: 1em;
    }
    p.rema_title {
      font-size: 1em;
    }
    .bloc1 {
      margin-left: 16em;
      margin-right: 16em;
      background-color: white;
      z-index: 99;
      top: -7em;
      box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
    }
    .second {
      padding: 2em 1em;
    }
    .objet {
      font-size: 1.5em;
      padding-bottom: 1em;
    }
    .objet_dev {
      font-weight: 300;
    }
    h2 {
      margin-top: -1em;
      padding-bottom: 1em;
    }
    .bloc6 {
      background-color: #16B853;
      padding: 5em 8em;
      color: white;
    }
    .footer_title {
      font-weight: 600;
      font-size: 1em;
    }
    .infos {
      font-size: 0.9em;
      font-weight: 300;
    }
    .infos a {
      color: white;
    }
  }
  @media only screen and (min-width: 1408px) {
    .page {
      font-family: 'Roboto', sans-serif;
      width: 100%;
          padding-left: 0%;
    padding-right: 0%;
    }
    .first {
      background-color: #006FB1;
      text-align: center;
      color: white;
      height: 30em;
      margin-top: -10px;
    }

    p.arrow {
      width: 1.5em;
    }
    .title {
      color: white;
      font-weight: 400;
      font-size: 2em;
      margin-top: 10;
      padding-top: 1em;
    }
    p.rema_title {
      font-size: 1em;
    }
    .bloc1 {
      margin-left: 16em;
      margin-right: 16em;
      background-color: white;
      z-index: 99;
      top: -7em;
      box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
    }
    .second {
      padding: 2em 1em;
    }
    .objet {
      font-size: 1.5em;
      padding-bottom: 1em;
    }
    .objet_dev {
      font-weight: 300;
    }
    h2 {
      margin-top: -1em;
      padding-bottom: 1em;
    }
    .bloc6 {
      background-color: #16B853;
      padding: 5em 8em;
      color: white;
    }
    .footer_title {
      font-weight: 600;
      font-size: 1em;
    }
    .infos {
      font-size: 0.9em;
      font-weight: 300;
    }
    .infos a {
      color: white;
    }
  }
</style>
